import clsx from 'clsx';
import React from 'react';

import {
  EStatsAlign,
  EStatsColor,
  EStatsNumberPosition,
  ESizeSettings,
} from './constants';

type TStat = { title?: string; subTitle?: string; description?: string };

export type TStatsV2Props = {
  stats: TStat[];
  color: EStatsColor;
  size: ESizeSettings;
  align: EStatsAlign;
  position: EStatsNumberPosition;
};

export const StatsV2: React.FC<TStatsV2Props> = props => {
  const {
    stats,
    color = EStatsColor.Black,
    size = ESizeSettings.Fixed,
    align = EStatsAlign.Left,
    position = EStatsNumberPosition.NumbersAbove,
  } = props;

  return (
    <div
      className={clsx(
        'grid flex-wrap gap-6',
        {
          [EStatsAlign.Left]:
            'grid-cols-[repeat(auto-fit,minmax(127px,1fr))] tablet:flex tablet:justify-center',
          [EStatsAlign.Center]:
            'grid-cols-[repeat(auto-fit,minmax(127px,1fr))] tablet:grid-cols-[repeat(auto-fit,minmax(168px,1fr))] desktop:flex desktop:justify-center',
        }[align],
      )}
    >
      {stats.map((stat, index) => (
        <div
          key={index}
          className={clsx(
            'flex flex-1 gap-2 overflow-hidden',
            'flex-col tablet:min-w-[168px] tablet:max-w-[260px] tablet:basis-[calc(20%-24px)] desktop:min-w-[166px] desktop:max-w-screen-sm',
            align === EStatsAlign.Center && 'tablet:items-center',
          )}
        >
          <h3
            className={clsx(
              '[overflow-wrap:anywhere]',
              {
                [ESizeSettings.Dynamic]:
                  'text-7xl font-normal leading-relaxed tablet:text-8xl desktop:text-10xl desktop:leading-normal',
                [ESizeSettings.Fixed]: 'text-8xl font-normal leading-[60px]',
              }[size],
              {
                [EStatsColor.White]: 'text-interface-50',
                [EStatsColor.Black]: 'text-interface-1000',
              }[color],
              {
                [EStatsNumberPosition.NumbersAbove]: 'order-1',
                [EStatsNumberPosition.NumbersUnder]: 'order-3',
              }[position],
            )}
          >
            {stat.title}
          </h3>

          {stat.subTitle ? (
            <h4
              className={clsx(
                {
                  [EStatsColor.White]: 'text-interface-50',
                  [EStatsColor.Black]: 'text-interface-1000',
                }[color],
                {
                  [EStatsNumberPosition.NumbersAbove]: 'order-2',
                  [EStatsNumberPosition.NumbersUnder]: 'order-1',
                }[position],
                'text-xl font-semibold leading-extra-loose tablet:text-2xl',
              )}
            >
              {stat.subTitle}
            </h4>
          ) : null}

          <p
            className={clsx(
              {
                [EStatsAlign.Center]:
                  'text-sm font-medium leading-loose tablet:text-center',
                [EStatsAlign.Left]: 'text-sm font-medium leading-[18.90px]',
              }[align],
              {
                [EStatsColor.Black]: 'text-interface-800',
                [EStatsColor.White]: 'text-interface-50',
              }[color],
              {
                [EStatsNumberPosition.NumbersAbove]: 'order-3',
                [EStatsNumberPosition.NumbersUnder]: 'order-2',
              }[position],
            )}
          >
            {stat.description}
          </p>
        </div>
      ))}
    </div>
  );
};
